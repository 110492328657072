:root {
    /* Breakpoints */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    /* Flex paddings  and margins*/
    --padding: 1rem;
    --vertical-padding: 1rem;
    --margin: .5rem;
    --vertical-margin: .5rem;
    /* Fixed header */
    --fixed-header-height: 10vh;
    /* Slick */
    --slick-slider-height: 45vh;
    /* Colors */
    --text-color: #55575A;
    --main-color: #fff;
    --accent-color: #E20613;
    --effect-color: #E20613;
    --header-text-color: #55585A;
    --header-bg-color: var(--main-color);
    --footer-bg-color: #56585B;
    --footer-text-color: #fff;
    --menu-bg-color: inherit;
    --menu-text-color: inherit;
    --menu-text-hover-color: #fff;
    --menu-text-bg-color: #fff;
    --menu-text-bg-hover-color: #BD3B77;
    /* Typo */
    --scale-factor: 1.618;
    --base-font-size: 16px;
    --base-line-height: 1.5;
    --scale-factor-m: var(--scale-factor);
    --scale-factor-s: 1.418;
    --scale-factor-xs: 1.218;
    /* Fonts */
    --base-font-family: Muli, sans-serif;
    --alternative-font-family: Laila, sans-serif;
    ;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.2.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Muli.css";
@import "/fontmodules/Laila.css";


/*--------------------------------------------------------------
General
--------------------------------------------------------------*/

html {
    height: 100%;
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
}

body {
    font-weight: 400;
    font-family: var(--base-font-family);
    font-size: var(--base-font-size);
    line-height: var(--base-line-height);
    color: var(--text-color);
}

header {
    background: var(--header-bg-color);
    color: var(--header-text-color);
    box-shadow: 0 3px 3px rgba(0,0,0,0.3);
}

footer {
    text-align: center;
    padding: 1rem 0;
    background: var(--footer-bg-color);
    color: var(--footer-text-color);
}

header a {
    text-decoration: none;
}

.logo {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor))rem;
}

.logo span {
    display: block;
}

.logo span:last-of-type {
    display: block;
    font-size: calc(1 * 1)rem;
}

.container {
    max-width: var(--container-max-width);
    padding: 0.5em;
    width: 100%;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}

.flex > * > img {
    flex: 0 0 auto;
}
/* cross-browser fix, obs! gäller endast för bilder i div utan klass*/
.flex > * > img:not([class]) {
    width: 100%;
}

figure {
    margin: 0;
}
        
/*--------------------------------------------------------------
Typography
--------------------------------------------------------------*/
h1 {
    font-size: calc(1 * var(--scale-factor) * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h2 {
    font-size: calc(1 * var(--scale-factor)rem);
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

h3 {
    font-size: 1rem;
    font-family: var(--alternative-font-family);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/*--------------------------------------------------------------
Menu
--------------------------------------------------------------*/

nav {
}

.menu.menu li {
    margin: 0;
}

.menu.menu li a {
    padding: .5rem .5rem;
    color: var(--menu-text-color);
    background: var(--menu-text-bg-color);
    font-family: var(--alternative-font-family);
    font-size: .9rem;
}

.menu.menu > li.active > a, .menu.menu > li > a:hover, .menu.menu > .activeparent > a {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
    border-radius: 0 10px;
}

.sub-menu-items.sub-menu-items, .sub-menu-items li, .menu.menu .dropdown ul {
    border-bottom: none;
}

.sub-menu-items > li.active > a, .sub-menu-items > li > a:hover {
    color: var(--menu-text-bg-hover-color);
}
nav.submenu ul li.veterinarmenu.activeparent a {
    text-decoration: none;
}

nav.submenu ul {
    list-style: none;
}

nav.submenu ul li {
    margin: 0 0 1rem 0;
    padding: 0;
}

.submenu li a {
    text-decoration: none;
    font-family: var(--alternative-font-family);
    font-size: .9rem;
}

.submenu > ul li.active > a, .submenu > ul li > a:hover {
    text-decoration: underline;
}


/*--------------------------------------------------------------
Slick slider
--------------------------------------------------------------*/

.slick-slider, .slick-substitute {
    margin: 0;
}

.slick-track {
    display: flex;
    flex-direction: row;
}

.slick-initialized .slick-slide, .slick-substitute > div {
    background-size: cover;
    background-position: center center;
    display: flex;
    height: 100%;
    min-height: var(--slick-slider-height);
}

.slider-title {
    max-width: var(--container-max-width);
    text-align: center;
}
/*--------------------------------------------------------------
Flexbox sticky footer by MW (add height:100% to html)
--------------------------------------------------------------*/

.site {
    display: flex;
    flex-direction: column;
    height: 100%;
}

main {
    flex: 1 0 auto;
    min-height: 1%;
 /* IE fix */
    ;
}

header, footer {
    flex: none;
}

/*--------------------------------------------------------------
Forms
--------------------------------------------------------------*/

.boltform *, .boltform input[type] {
    border-radius: 0px;
}

.boltform {
    background: #56585B;
    color: #fff;
    padding: 1rem;
    margin-top: .5rem;
}

.boltform input, .boltform textarea {
    width: 100%;
    color: #55575a;
}

.boltform button {
    background: var(--menu-text-bg-hover-color);
    color: var(--menu-text-hover-color);
    border: none;
    margin: 0;
}

.boltform button:hover {
    background: color(var(--menu-text-bg-hover-color) lightness(60%));
    color: var(--menu-text-hover-color);
}

.boltform .boltforms-row label.required:after {
    content: " *";
    color: var(--menu-text-bg-hover-color);
}

.intenditfooter {
    font-size: 10px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.4);
    margin: 0 .1rem 0 0;
    text-align: right;
}

.intenditfooter a {
    text-decoration: none;
}

.boxwrapper {
    min-height: 28vh;
    background-size: 0;
    position: relative;
    overflow: hidden;
    text-decoration: none;
}

.boxwrapper[style]:before, .boxwrapper:after, .boxwrapper figure img, .boxwrapper figure figcaption {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.boxwrapper[style]:before {
    content: '';
    background-image: inherit;
    background-size: cover;
    background-position: center center;
    transition: transform .3s ease-in 0s;
}

.boxwrapper figure img {
    transition: transform .3s ease-in 0s;
}

.boxwrapper:after {
    content: '';
    background: rgba(0,0,0,0);
    transition: background .3s ease-in 0s;
    z-index: 2;
}

.boxwrapper:hover:after {
    background: rgba(0,0,0,0.3);
    transition: background .8s ease-in 0s;
}

.boxwrapper[style]:hover:before, .boxwrapper:hover figure img {
    transform: scale(1.3);
    transition: transform .8s ease-in 0s;
}

.boxwrapper h2 {
    z-index: 3;
}

.boxwrapper h2 {
    color: #fff;
    text-transform: none;
    background: #BD3B77;
    padding: .5rem;
    font-size: calc(.7 * var(--scale-factor)rem);
    width: 90%;
    border-radius: 0 0 0 10px;
    font-weight: 400;
}

.kontaktwrapper, .newsarchive, nav.submenu {
    background: #bd3b77;
    color: #fff;
}

.kontaktwrapper svg {
    fill: #fff;
}

header svg {
    fill: #BD3B77;
}

.newsarchive {
    margin-top: var(--vertical-margin);
}

.newsarchive ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.newsarchive ul li {
    padding: 0;
    margin: 0;
}

.newsitemwrapper {
    background: #e6e6e6;
    margin-bottom: var(--vertical-margin);
}

.newsitemwrapper:nth-of-type(even) {
    background: none;
}


/*--------------------------------------------------------------
Maps
--------------------------------------------------------------*/

.map-canvas {
    height: 35vh;
}

.map-canvas img {
}

@media (max-width: var(--breakpoint-m)) {
      
    h1 {
        font-size: calc(1 * var(--scale-factor-m) * var(--scale-factor-m)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-m)rem);
    }
}

@media (max-width: var(--breakpoint-s)) {
    
    h1 {
        font-size: calc(1 * var(--scale-factor-s) * var(--scale-factor-s)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-s)rem);
    }
}

@media (max-width: var(--breakpoint-xs)) {
    h1 {
        font-size: calc(1 * var(--scale-factor-xs) * var(--scale-factor-xs)rem);
    }

    h2 {
        font-size: calc(1 * var(--scale-factor-xs)rem);
    }
}

@media (max-width: var(--breakpoint-menu)) {
    
    .menu.menu {
        background: var(--menu-bg-color);
    }

    .menu.menu li {
        margin: 0;
    }

    .menu.menu li a {
        margin: 0;
    }
}

iframe {
    max-width: 100%;
    width: 100%;
    height: 35vh;
    border: none;
}
